<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ tipoServico.id ? 'Atualizar Instrumento' : 'Novo Instrumento' }}</h5>
                <div class="field col-12 md:col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="tipoServico.title" />
                </div>
                <div class="field col-12 md:col-9">
                    <div class="grid row align-items-end">
                        <div class="field col-12 md:col-6">
                            <label class="required">Tipo de Serviço</label>
                            <Dropdown v-model="tipoServico.modalidadeTipoServico.id" :options="modalidadeTipoServico" optionLabel="title" optionValue="id" />
                        </div>
                        <!-- <div class="field col-12 md:col-4">
                            <label class="required">Natureza</label>
                            <Dropdown v-model="tipoServico.natureza.id" :options="natureza" optionLabel="title" optionValue="id" />
                        </div> -->
                        <div class="field col-12 md:col-6">
                            <label class="required">Abordagem</label>
                            <Dropdown v-model="tipoServico.tipoAtendimento.id" :options="tipoAtendimento" optionLabel="title" optionValue="id" />
                        </div>
                    </div>
                    <div class="grid row align-items-end">
                        <div class="field col-12 md:col-4">
                            <label class="required">Instrumento de Atendimento</label>
                            <Dropdown v-model="tipoServico.instrumento.id" :options="instrumento" optionLabel="title" optionValue="id" />
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="currency-br">Limite Valor</label>
                            <InputNumber id="currency-br" v-model="tipoServico.limiteValor" mode="currency" currency="BRL" locale="pt-BR" />
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="minmax-buttons">Número Mínimo de Participantes</label>
                            <InputNumber id="minmax-buttons" v-model="tipoServico.minimoParticipantes" mode="decimal" showButtons :min="0" />
                        </div>
                    </div>
                    <div class="grid row align-items-end">
                        <div class="field col-12 md:col-4">
                            <label for="minmax-buttons">Número Máximo de Participantes</label>
                            <InputNumber id="minmax-buttons" v-model="tipoServico.maximoParticipantes" mode="decimal" showButtons :min="0" />
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="minmax-buttons">Carga Horária Mínima</label>
                            <InputNumber id="minmax-buttons" v-model="tipoServico.cargaHorariaMinima" mode="decimal" showButtons :min="0" />
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="minmax-buttons">Carga Horária Maxima</label>
                            <InputNumber id="minmax-buttons" v-model="tipoServico.cargaHorariaMaxima" mode="decimal" showButtons :min="0" />
                        </div>
                    </div>
                </div>
                <div class="field col-9">
                    <label class="required">Tipo de Serviço Sas</label>
                    <Dropdown v-model="tipoServico.codigoSas" :options="tipoServicoSas" optionValue="ID" optionLabel="Nome" showClear />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Conceito (caderno técnico)</label>
                    <Textarea v-model="tipoServico.conceito" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Requisitos</label>
                    <Textarea v-model="tipoServico.requisitos" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Entrega Esperada</label>
                    <Textarea v-model="tipoServico.entregaEsperada" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Forma de Avaliação</label>
                    <Textarea v-model="tipoServico.formaAvaliacao" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Observações</label>
                    <Textarea v-model="tipoServico.observacoes" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field col-12 md:col-9">
                    <label>Remuneracao Credenciado</label>
                    <InputNumber v-model="tipoServico.remuneracaoCredenciado" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" />
                </div>

                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="tipoServico.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button
                label="Salvar"
                @click.stop="inserir"
                icon="pi pi-check-circle"
                :disabled="
                    !tipoServico.title ||
                    !tipoServico.modalidadeTipoServico.id ||
                    !tipoServico.instrumento.id ||
                    !tipoServico.codigoSas ||
                    // !tipoServico.natureza.id ||
                    !tipoServico.tipoAtendimento.id ||
                    !participanteValido ||
                    !cargaHorariaValida
                "
            ></Button>
        </div>
    </div>
</template>

<script>
import Services from './service';
import ModalidadeService from '../modalidadetiposervico/service';
import NaturezaService from '../naturezas/service';
import TipoAtendimentoService from '../tipoatendimento/service';
import InstrumentosService from '../instrumentos/service';

export default {
    data() {
        return {
            participanteValido: false,
            cargaHorariaValida: false,
            tipoServico: {
                title: null,
                modalidadeTipoServico: {
                    id: null,
                },
                // natureza: {
                //     id: null,
                // },
                tipoAtendimento: {
                    id: null,
                },
                instrumento: {
                    id: null,
                },
                limiteValor: null,
                minimoParticipantes: null,
                maximoParticipantes: null,
                cargaHorariaMinima: null,
                cargaHorariaMaxima: null,
                conceito: null,
                requisitos: null,
                entregaEsperada: null,
                formaAvaliacao: null,
                observacoes: null,
                active: true,
                codigoSas: null,
                descricaoSas: null,
                remuneracaoCredenciado: null,
            },
            modalidadeTipoServico: null,
            natureza: null,
            tipoAtendimento: null,
            instrumento: [],
            tipoServicoSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.tipoServico.title = response.data.title;
                    this.tipoServico.limiteValor = response.data.limiteValor;
                    this.tipoServico.minimoParticipantes = response.data.minimoParticipantes;
                    this.tipoServico.maximoParticipantes = response.data.maximoParticipantes;
                    this.tipoServico.cargaHorariaMinima = response.data.cargaHorariaMinima;
                    this.tipoServico.cargaHorariaMaxima = response.data.cargaHorariaMaxima;
                    this.tipoServico.conceito = response.data.conceito;
                    this.tipoServico.requisitos = response.data.requisitos;
                    this.tipoServico.entregaEsperada = response.data.entregaEsperada;
                    this.tipoServico.formaAvaliacao = response.data.formaAvaliacao;
                    this.tipoServico.observacoes = response.data.observacoes;
                    this.tipoServico.active = response.data.active;
                    this.tipoServico.codigoSas = response.data.codigoSas;
                    this.tipoServico.descricaoSas = response.data.descricaoSas;
                    this.tipoServico.remuneracaoCredenciado = response.data.remuneracaoCredenciado;
                    this.tipoServico.id = response.data.id;
                    this.tipoServico.instrumento.id = response.data.instrumento ? response.data.instrumento.id : null;
                    this.tipoServico.modalidadeTipoServico.id = response.data.modalidadeTipoServico ? response.data.modalidadeTipoServico.id : null;
                    // this.tipoServico.natureza.id = response.data.natureza ? response.data.natureza.id : null;
                    this.tipoServico.tipoAtendimento.id = response.data.tipoAtendimento ? response.data.tipoAtendimento.id : null;
                }
            });
        }
        this.obterModalidades();
        // this.obterNaturezas();
        this.obterTipoAtendimento();
        this.obterInstrumentos();
        this.obterTipoServicoSas();
    },
    methods: {
        inserir() {
            if (this.tipoServico.id) {
                Services.atualizar(this.tipoServico.id, this.tipoServico).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                this.$store.dispatch('addRequest');
                Services.inserir(this.tipoServico).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Instrumento',
                    detail: `Instrumento ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterModalidades() {
            this.$store.dispatch('addRequest');
            ModalidadeService.obterTodos().then((response) => {
                if (response?.success) {
                    this.modalidadeTipoServico = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterNaturezas() {
            this.$store.dispatch('addRequest');
            NaturezaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.natureza = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterTipoAtendimento() {
            this.$store.dispatch('addRequest');
            TipoAtendimentoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.tipoAtendimento = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterInstrumentos() {
            this.$store.dispatch('addRequest');
            InstrumentosService.obterTodos().then((response) => {
                if (response?.success) {
                    this.instrumento = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterTipoServicoSas() {
            this.$store.dispatch('addRequest');
            Services.obterTipoServicoSas().then((response) => {
                if (response?.success) {
                    this.tipoServicoSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let tipoServicoDto = {
                        ID: this.tipoServico.codigoSas,
                        Nome: this.tipoServico.descricaoSas,
                    };
                    this.tipoServicoSas.push(tipoServicoDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `Instrumentos`,
            });
        },
        validarParticipantes(obj1, obj2, msg = 'Número Mínimo de Participantes não deve ser maior que o Número Máximo de Participantes') {
            if (obj1 != null && obj2 != null) {
                if (obj2 > obj1) {
                    this.participanteValido = false;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${msg}`,
                        life: 7000,
                    });
                }
                if (obj1 >= obj2) {
                    this.participanteValido = true;
                }
            }
        },
        validarCargaHoraria(obj1, obj2, msg = 'Carga Horária Mínima não deve ser maior que Carga Horária Maxima') {
            if (obj1 != null && obj2 != null) {
                if (obj2 > obj1) {
                    this.cargaHorariaValida = false;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${msg}`,
                        life: 7000,
                    });
                }
                if (obj1 >= obj2) {
                    this.cargaHorariaValida = true;
                }
            }
        },
        validarCargaHorariaNull(obj1, obj2) {
            if (obj1 == null || obj2 == null) {
                this.cargaHorariaValida = true;
            }
        },
        validarParticipantesNull(obj1, obj2) {
            if (obj1 == null || obj2 == null) {
                this.participanteValido = true;
            }
        },
    },
    watch: {
        'tipoServico.codigoSas'() {
            if (this.tipoServico.codigoSas > 0 && this.tipoServicoSas.length > 0) {
                const result = this.tipoServicoSas.filter((tipoServico) => tipoServico.ID == this.tipoServico.codigoSas);
                this.tipoServico.descricaoSas = result[0].Nome;
                this.tipoServico.codigoSas = result[0].ID;
            }
        },
        'tipoServico.maximoParticipantes'() {
            this.validarParticipantes(this.tipoServico.maximoParticipantes, this.tipoServico.minimoParticipantes);
        },
        'tipoServico.minimoParticipantes'() {
            this.validarParticipantes(this.tipoServico.maximoParticipantes, this.tipoServico.minimoParticipantes);
        },
        'tipoServico.cargaHorariaMinima'() {
            this.validarCargaHoraria(this.tipoServico.cargaHorariaMaxima, this.tipoServico.cargaHorariaMinima);
        },
        'tipoServico.cargaHorariaMaxima'() {
            this.validarCargaHoraria(this.tipoServico.cargaHorariaMaxima, this.tipoServico.cargaHorariaMinima);
        },

        tipoServico: {
            handler() {
                this.validarParticipantesNull(this.tipoServico.maximoParticipantes, this.tipoServico.minimoParticipantes);
                this.validarCargaHorariaNull(this.tipoServico.cargaHorariaMaxima, this.tipoServico.cargaHorariaMinima);
            },
            deep: true,
        },
    },
};
</script>
